export default {

	data() {
		return {
			intRef: null,
			merchantIsActive: null,
			checkLocationId: null,
		};
	},

	computed: {

		isLocationOpen() {
			return !!this.merchantIsActive;
		},

	},

	beforeDestroy() {
		this.clearStatusChecker();
	},

	methods: {

		startStatusChecker(location, onStatusChange) {
			this.clearStatusChecker();


			this.checkLocationId = location.id;
			this.intRef = setInterval(() => this.checkLocationStatus(onStatusChange), 10 * 1000);

			// Initial Check
			this.checkLocationStatus(onStatusChange);
		},

		async checkLocationStatus(onStatusChange) {
			try {
				const { data } = await this.$api.get(`/browse/merchant/locations/${this.checkLocationId}/status`);

				if (this.merchantIsActive !== null && this.merchantIsActive !== data.is_active) {
					onStatusChange({
						locationId: this.checkLocationId,
						wasOpen: this.merchantIsActive,
						isOpen: data.is_active,
					});
				}
				this.merchantIsActive = data.is_active;
			} catch (e) {
				// Fail Silently
				this.$rollbar.info('Could not fetch location status', e.message);
				throw e;
			}
		},

		clearStatusChecker() {
			if (this.intRef) {
				clearInterval(this.intRef);
			}
		},



	}

};
