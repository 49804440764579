import _ from 'underscore';

export default {

	data() {
		return {
			submitAttempted: false,
			formSaving: false,
			errors: {},
		};
	},

	computed: {

		hasErrors() {
			if (_.isArray(this.errors)) return this.errors.length > 0;

			if (_.isObject(this.errors)) return Object.values(this.errors).length > 0;

			if (this.submitAttempted && this.$v) {
				return this.$v.$invalid;
			}

			return false;
		},

	},

	methods: {

		async submit(callback, errorCallback = () => false) {
			this.submitAttempted = true;

			if ((this.$v && this.$v.$invalid) || this.formSaving) return null;

			let result = false;

			this.errors = {};
			this.formSaving = true;
			try {
				result = await callback();
			} catch (e) {
				this.errors = this.$api.getFormErrors(e);
				result = errorCallback(e);
			}

			this.formSaving = false;

			return result;
		},

		resetErrors() {
			this.errors = {};
		}
	}


};
